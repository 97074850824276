import React, { useState, useEffect, useContext } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import 'react-html5-camera-photo/build/css/index.css'
import JobDetails from './JobDetails.js'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import JobTechs from './JobTechs'
import { Redirect } from 'react-router'
import Timecard from './Timecard'
import InvoiceList from './InvoiceList'
import JobPdfList from './JobPdfList'
import JobVideoList from './JobVideoList'
import RoofInspectionList from './RoofInspectionList'
import AddMaterialsDialog from './AddMaterialsDialog'
import CompleteJobDialog from './CompleteJobDialog'
import ReturnJobDialog from './ReturnJobDialog'
import ReturnJobMwDialog from './ReturnJobMwDialog'
import SimpleInvoiceDialog from './SimpleInvoiceDialog'
import SimpleVideoDialog from './SimpleVideoDialog'
import SimplePdfDialog from './SimplePdfDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RoofContext } from "../../AppContext";
import { config } from '../../config'

const JobDoc = props => {
    const __ID = props.match.params.id

    const getDecodedUrl = async (url) => {
      const path = config.API_ENDPOINT + '/api/create/pdf_url'
      const decodedUrl = decodeURIComponent(url)
      console.log("url: " + url)
      const request = {
          method: 'POST',
          headers: { 
              Accept: 'application/json'
          },
          body: JSON.stringify({pdf_path: decodedUrl})
      };
      const result = await fetch(path, request);
      let obj = await result.json();
      console.log("path: " + decodeURIComponent(obj.pdf_path))
      window.open(decodeURIComponent(obj.pdf_path), '_blank')
     
      // return "https://google.com"
  }
  useEffect(() => {
    // Step 1 - pull URL based off of DOCS ID
    // Step 2 - decodeURL
    // getDecodedUrl(jobVideo.fieldData.S3Location.replace('https://duraroof-app.s3.amazonaws.com/', ''))
    console.log('test1');
    console.log(__ID);
  })
    
    return (<div></div>)
}
export default JobDoc